import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawFour3.css';

const LawFour3: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="LawFour-Three Law-Sentence-Center LawFour-Content">
        <FormattedMessage 
          id="fourlaws.law4.three"
          defaultMessage={'When We Receive Christ, We Experience a New Birth (Read John 3:1-8.)'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/four/2 (<LawFour2 />)
        * nextTo: /4laws/four/4 (<LawFour4 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
        nextTo={`../${FourLawsPaths.INDEX_4}`}
      />
    </React.Fragment>
  );
};

export default LawFour3;