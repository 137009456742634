import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';

const GracePrep5: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center Law-Sentence-Bold">
        <FormattedMessage 
          id="fourlaws.grace.prep.five"
          defaultMessage={'5. The great adventure for which God created you will begin.'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace-prep/6 (<GracePrep4 />)
        * nextTo: /4laws/grace-prep/8 (<GracePrepWrapup1 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_6}`}
        nextTo={`../${FourLawsPaths.INDEX_8}`}
      />
    </React.Fragment>
  );
};

export default GracePrep5;