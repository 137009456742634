import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FourLawsPaths } from "../../../../../paths";
import ConfirmThirdQuestion1 from "./ConfirmThirdQuestion1";
import ConfirmThirdQuestion2 from "./ConfirmThirdQuestion2";

const ConfirmThirdQuestionRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<ConfirmThirdQuestion1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<ConfirmThirdQuestion2 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default ConfirmThirdQuestionRoutes;