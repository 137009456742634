import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType, PageType } from '../../../../../interfaces/page.interfaces';
import actionCreators from '../../../../../state/actionCreators';
import { FourLawsPaths } from '../../../../paths';
import './PrayerReview1.css';

const PrayerReview1: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onNoClick = () => {
    dispatch(actionCreators.setGreetFirstAnswer(PageType.REVIEW));
  };

  const yesText = intl.formatMessage({
    id: "fourlaws.prayer.review.one.yes",
    defaultMessage: "Yes"
  });
  const noText = intl.formatMessage({
    id: "fourlaws.prayer.review.one.no",
    defaultMessage: "No"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Prayer-Review1">
        <div className="Law-Sentence-Center Law-Sentence-Margin Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.prayer.review.one"
            defaultMessage={'Have you ever accepted Jesus Christ in this way?'}
          />
        </div>
      </PageText>
      <div className="Law-Question-Answer">
        {/* 
        * previousTo: /4laws/prayer/6 (<PrayerReview2 />)
        * nextTo: /4laws/prayer/3 (<Prayer3 />)
        */}
        <BottomNavigation 
          previousTo={`../${FourLawsPaths.INDEX_6}`}
          previousText={yesText}
          nextTo={`../${FourLawsPaths.INDEX_3}`}
          nextText={noText}
          hideArrow={true}
          onNextClick={onNoClick}
        />
      </div>
      {/* to: /4laws/prayer/2 (<Prayer2 />) */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default PrayerReview1;