import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Answer from '../../../../../../../components/Answer';
import BottomNavigation from '../../../../../../../components/BottomNavigation';
import PageText from '../../../../../../../components/PageText';
import { PageTextType } from '../../../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../../../paths';
import './ConfirmThirdQuestion1.css';

const ConfirmThirdQuestion1: React.FC = () => {
  const intl = useIntl();
  const answer = intl.formatMessage({
    id: "fourlaws.confirm.third.question.one.answer",
    defaultMessage: "No"
  });
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-PageContent Law-LongSentence">
        <div className="Law-ConfirmThird-Words">
          <FormattedMessage 
            id="fourlaws.confirm.third.words"
            defaultMessage={'"...for He Himself has said I will never leave you, nor will I ever forsake you" (Hebrews 13:5)'}
          />
        </div>
        <div className="Law-Sentence-Center Law-Question-Below-words Law-ConfirmThird-Q1-Title">
          <FormattedMessage 
            id="fourlaws.confirm.third.question.one"
            defaultMessage="After you receive Christ personally, will He ever leave you?"
          />
        </div>
        <Answer className="Answer-Confirm-third-question1" answer={answer} />
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/third/1 (<ConfirmThird1 />)
        * nextTo: /4laws/confirm/third/question/2 (<ConfirmThirdQuestion2 />)
        */}
      <BottomNavigation 
        previousTo={`../../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default ConfirmThirdQuestion1;