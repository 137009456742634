export enum FourLawsPaths {
  LOCALE = ':locale',
  EN = 'en',
  FOUR_LAWS = '4laws',
  MAIN = 'main',
  LAW_ONE = 'one',
  LAW_TWO = 'two',
  LAW_THREE = 'three',
  LAW_FOUR = 'four',
  GREET = 'greet', //part of law 4
  PRAYER = 'prayer',
  CONFIRM = 'confirm',
  CONFIRM_FIRST = 'first',
  CONFIRM_SECOND = 'second',
  CONFIRM_THIRD = 'third',
  CONFIRM_QUESTION = 'question',
  FEELINGS = 'feelings',
  GRACE = 'grace',
  GRACE_PREP = 'grace-prep',
  SUGGEST = 'suggest',
  WRAPUP = 'wrapup',
  INDEX_0 = '0',
  INDEX_1 = '1',
  INDEX_2 = '2',
  INDEX_3 = '3',
  INDEX_4 = '4',
  INDEX_5 = '5',
  INDEX_6 = '6',
  INDEX_7 = '7',
  INDEX_8 = '8',
  INDEX_9 = '9'
}