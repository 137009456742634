import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import BottomNavigation from '../../../../../../components/BottomNavigation';
import Link from '../../../../../../components/Link';
import PageText from '../../../../../../components/PageText';
import { ConfirmFirstResponseType, PageTextType } from '../../../../../../interfaces/page.interfaces';
import actionCreators from '../../../../../../state/actionCreators';
import { FourLawsPaths } from '../../../../../paths';
import './ConfirmFirst1.css';

const ConfirmFirst1: React.FC = () => {
  const dispatch = useDispatch();
  const onDontknowClick = () => {
    dispatch(actionCreators.setConfirmFirstResponseType(ConfirmFirstResponseType.DONT_KNOW));
  };

  const onNextClick = () => {
    dispatch(actionCreators.setConfirmFirstResponseType(undefined));
  };

  return (
    <div className="Law-Question">
      <PageText type={PageTextType.CONTENT} className="Law-PageContent">
        <div className="Law-Sentence-Center Law-Sentence-Bold Law-ConfirmFirst1-Question">
          <FormattedMessage 
            id="fourlaws.confirm.first.one"
            defaultMessage="As you prayed did you receive Christ into your life? Because of His promise in Revelation 3:20, where is Christ right now in relation to you?"
          />
        </div>
        <div className="Law-Question-Answer-Single">
          {/* to: /4laws/confirm/first/question/1 (<ConfirmFirstQuestion1 />) */}
          <Link to={`../${FourLawsPaths.CONFIRM_QUESTION}`} onClick={onDontknowClick}>
            <FormattedMessage 
              id="fourlaws.confirm.first.one.dontknow"
              defaultMessage="Review Revelation 3:20"
            />
          </Link>
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/0 (<ConfirmMain />) 
        * nextTo: /4laws/confirm/first/2 (<ConfirmFirst2 />)
        */}
      <BottomNavigation 
        previousTo={`../../${FourLawsPaths.INDEX_0}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
        onNextClick={onNextClick}
      />
    </div>
  );
};

export default ConfirmFirst1;