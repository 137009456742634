import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Grace2.css';
import Question from '../../../../../components/Question';
import Word from '../../../../../components/Word';

const Grace2: React.FC = () => {
  const [minimized, setMinimized] = useState(false);
  const [verseIndex, setVerseIndex] = useState(0);

  const onNavigateClick = (index: number) => {
    setMinimized(!(index === 0 || index === 2));
    setVerseIndex((index < 2) ? 0: 1);
  };

  // class for minimizing word(s) when question is displayed.
  let className = "Law-Dialog-Title";
  className += (minimized) ? ' Law-Dialog-Title-Minimized' : '';

  const intl = useIntl();

  // Col 1:14 word & address and Q & A
  const col114Word = intl.formatMessage({
    id: "fourlaws.grace.two.words1",
    defaultMessage: "\"in whom we have redemption, the forgiveness of sins.\""
  });

  const col114Address = intl.formatMessage({
    id: "fourlaws.grace.two.words1.address",
    defaultMessage: "(Colossians 1:14)"
  });

  const question1 = intl.formatMessage({
    id: "fourlaws.grace.two.question1",
    defaultMessage: "What did God do with your sins?"
  });
  const answer1 = intl.formatMessage({
    id: "fourlaws.grace.two.question1.answer",
    defaultMessage: "Forgave them"
  });

  // Col 2:13 word & address and Q & A
  const col213Word = intl.formatMessage({
    id: "fourlaws.grace.two.words2",
    defaultMessage: "\"When you were dead in your transgressions and the uncircumcision of your flesh, He made you alive together with Him, having forgiven us all our transgressions\""
  });

  const col213Address = intl.formatMessage({
    id: "fourlaws.grace.two.words2.address",
    defaultMessage: "(Colossians 2:13)"
  });

  const question2 = intl.formatMessage({
    id: "fourlaws.grace.two.question2",
    defaultMessage: "How many of your sins did He forgive?"
  });
  const answer2 = intl.formatMessage({
    id: "fourlaws.grace.two.question2.answer",
    defaultMessage: "All of them"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.grace.two"
            defaultMessage={'2. Your sins were forgiven.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.grace.two.address"
            defaultMessage={'(Colossians 1:14; Colossians 2:13)'}
          />
        </div>
        <Dialog 
          contents={[
            <div></div>, 
            <Question
              question={question1}
              qClassName="Law-Grace2-Q1"
              answer={answer1}
              aClassName="Answer-Grace2-Q1"
            />,
            <div></div>, 
            <Question
              question={question2}
              answer={answer2}
              aClassName="Answer-Grace2-Q2"
            />
          ]} 
          title={
            (verseIndex === 0) ? 
            <Word 
              className={className}
              wordClassName={"Law-Grace2-Col114"} 
              word={col114Word} 
              address={col114Address} 
            /> :
            <Word 
              className={className}
              wordClassName="Law-Grace2-Col213" 
              word={col213Word} 
              address={col213Address} 
            />
          }
          onNavigateClick={onNavigateClick}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace/1 (<Grace1 />)
        * nextTo: /4laws/grace/3 (<Grace3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
 
  );
};

export default Grace2;