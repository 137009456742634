import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawOne1.css';

const LawOne1: React.FC = () => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawOne-Content Law-LongSentence LawOne-One"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law1.one.title"
            defaultMessage="God's Love"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law1.one.content"
            defaultMessage={'"For God so loved the world, that He gave His only begotten Son,' + 
                            ' that whoever believes in Him should not perish, but have eternal life" (John 3:16).'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/one/0 (<LawOneTitle />)
        * nextTo: /4laws/one/2 (<LawOne2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_0}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default LawOne1;