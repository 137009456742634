import React from 'react';
import BottomNavigation from '../../../../../components/BottomNavigation';
import { FourLawsPaths } from '../../../../paths';

const LawThreeTitle: React.FC = () => {
  return (
    /*
     * previousTo: /4laws/two/5 (<LawTwoWrapup />)
     * nextTo: /4laws/three/1 (<LawThree1 />)
     */
    <BottomNavigation 
      previousTo={`../../${FourLawsPaths.LAW_TWO}/${FourLawsPaths.INDEX_5}`}
      nextTo={`../${FourLawsPaths.INDEX_1}`}
    />
  );
};

export default LawThreeTitle;