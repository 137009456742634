import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './Home';
import { FourLawsPaths } from './paths';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.LOCALE + '/*'} element={<Home />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.EN} replace />} />
    </Routes>
  );
};

export default AppRoutes;
