import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import Prayer1 from './Prayer1';
import Prayer2 from './Prayer2';
import Prayer3 from './Prayer3';
import Prayer4 from './Prayer4';
import PrayerReview1 from './PrayerReview1';
import PrayerReview2 from './PrayerReview2';

const PrayerRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<Prayer1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<Prayer2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<Prayer3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<Prayer4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<PrayerReview1 />} />
      <Route path={FourLawsPaths.INDEX_6} element={<PrayerReview2 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default PrayerRoutes;
