import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType, PageType } from '../../../../../interfaces/page.interfaces';
import { AppState } from '../../../../../state/reducer';
import { FourLawsPaths } from '../../../../paths';
import './Prayer1.css';

const Prayer1: React.FC = () => {
  const greetSecondAnswer: PageType | undefined = useSelector((state: AppState) => state.greetSecondAnswer);
  const isPrep: boolean = greetSecondAnswer === PageType.PREP;
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="Law-PageContent Law-LongSentence Prayer-One"
      >
        <div className="Law-Sentence-Margin Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.prayer.one.line1"
            defaultMessage="YOU CAN RECEIVE CHRIST RIGHT NOW by FAITH THROUGH PRAYER. (Prayer is talking with God)"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.prayer.one.line2"
            defaultMessage={'God knows your heart and is not so concerned with your words as He is with the attitude of your heart.'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo(isPrep=true): /4laws/four/7 (<LawFourWrapupPrep />)
        * previousTo(isPrep=false): /4laws/four/6 (<LawFourWrapup />)
        * nextTo: /4laws/prayer/2 (<Prayer2 />)
        */}
      <BottomNavigation 
        previousTo={(isPrep) ? `../../${FourLawsPaths.LAW_FOUR}/${FourLawsPaths.INDEX_7}`: 
                                `../../${FourLawsPaths.LAW_FOUR}/${FourLawsPaths.INDEX_6}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default Prayer1;