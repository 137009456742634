import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawTwo2.css';

const LawTwo2: React.FC = () => {
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawTwo-Content Law-LongSentence LawTwo-Two"
      >
        <FormattedMessage 
          id="fourlaws.law2.two"
          defaultMessage={"Man was created to have fellowship with God; but, because of his stubborn self-will, " + 
                          "he chose to go his own independent way and fellowship with God was broken. " + 
                          "This self-will, characterized by an attitude of active rebellion or passive indifference, " + 
                          "is evidence of what the Bible calls sin."}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/two/1 (<LawTwo1 />)
        * nextTo: /4laws/two/3 (<LawTwo3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default LawTwo2;