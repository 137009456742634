import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import { Language } from '../../../../../interfaces/language.interfaces';
import './LawFour4.css';

const LawFour4: React.FC = () => {
  const intl = useIntl();
  const isKorean = intl.locale === Language.koRR || 
                    intl.locale === Language.koNT;
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawFour-Content Law-LongSentence LawFour-Four"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law4.four.title"
            defaultMessage="We Receive Christ by Personal Invitation"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law4.four.content"
            defaultMessage={'(Christ speaking): "Behold, I stand at the door and knock; ' + 
                            'if anyone hears My voice and opens the door, I will come in to him" (Revelation 3:20).'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo(language=ko-RR || ko-NT): /4laws/four/2 (<LawFour2 />)
        * previousTo(language=en): /4laws/four/3 (<LawFour3 />)
        * nextTo: /4laws/four/5 (<LawFour5 />)
        */}
      <BottomNavigation 
        previousTo={(isKorean) ? `../${FourLawsPaths.INDEX_2}` : `../${FourLawsPaths.INDEX_3}`}
        nextTo={`../${FourLawsPaths.INDEX_5}`}
      />
    </React.Fragment>
  );
};

export default LawFour4;