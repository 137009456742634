import { Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '../Stepper';
import './Dialog.css';
import { IGeneralProps, PageTextType } from '../../interfaces/page.interfaces';
import PageText from '../PageText';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducer';

export interface IAppDialogProps extends IGeneralProps {
  title?: React.ReactNode;
  contents: React.ReactNode[];
  onNavigateClick?: (index: number) => void;
  buttonText?: string | undefined;
}

const AppDialog: React.FC<IAppDialogProps> = (props) => {
  const { title, contents, onNavigateClick, buttonText, className } = props;
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    if (onNavigateClick) {
      setTimeout(() => onNavigateClick(0), 600);
    }
  };
  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const intl = useIntl();
  const language = useSelector((state: AppState) => state.language);
  const localeClass = `Language-${language} Locale-${intl.locale}`;
  const dialogClass = (className) ? `${className} ${localeClass}` : localeClass;
  
  const defaultText = intl.formatMessage({
    id: 'dialogs.defaultText',
    defaultMessage: 'Read Verses'
  });
  return (
    <React.Fragment>
      <button className="AppDialog-Button Law-Button" onClick={onOpen}>
        <PageText type={PageTextType.CONTENT}>
          { buttonText || defaultText }
        </PageText>
      </button>
      <Dialog
        className={`AppDialog ${dialogClass}`}
        fullWidth={true}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="app-dialog"
      >
        <DialogContent>
          <IconButton aria-label="close" className="AppDialog-CloseButton" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          { title }
          <Stepper className="AppDialog-Stepper" contents={contents} onNavigateClick={onNavigateClick} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AppDialog;