import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import BottomNavigation from '../../../../../../components/BottomNavigation';
import PageText from '../../../../../../components/PageText';
import { ConfirmFirstResponseType, PageTextType } from '../../../../../../interfaces/page.interfaces';
import { AppState } from '../../../../../../state/reducer';
import { FourLawsPaths } from '../../../../../paths';
import './ConfirmFirst2.css';

const ConfirmFirst2: React.FC = () => {
  const confirmFirstResponse: ConfirmFirstResponseType | undefined = useSelector((state: AppState) => state.confirmFirstResponse);
  let previousTo: string;
  if (confirmFirstResponse === ConfirmFirstResponseType.DONT_KNOW) {
    // to: /4laws/confirm/first/question/1 (<ConfirmFirstQuestion1 />)
    previousTo = `../${FourLawsPaths.CONFIRM_QUESTION}`;
  } else if (confirmFirstResponse === ConfirmFirstResponseType.QUESTION) {
    // to: /4laws/confirm/first/question/6 (<ConfirmFirstQuestion6 />)
    previousTo = `../${FourLawsPaths.CONFIRM_QUESTION}/${FourLawsPaths.INDEX_6}`;
  } else {
    // to: /4laws/confirm/first/1 (<ConfirmFirst1 />)
    previousTo = `../${FourLawsPaths.INDEX_1}`;
  }
  
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="Law-LongSentence ConfirmFirst-Two"
      >
        <FormattedMessage 
          id="fourlaws.confirm.first.two"
          defaultMessage={'Christ said that He would come into your life. Would He mislead you? ' + 
                          'Therefore, how do you know that God has answered your prayer? ' + 
                          '(The trustworthiness of God Himself and His Word, the Bible. In other words, God keeps His Promises).'}
        />
      </PageText>
      {/* nextTo: /4laws/confirm/second/1 (<ConfirmSecond1 />) */}
      <BottomNavigation 
        previousTo={previousTo}
        nextTo={`../../${FourLawsPaths.CONFIRM_SECOND}`}
      />
    </React.Fragment>
 
  );
};

export default ConfirmFirst2;