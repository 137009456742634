import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Suggest4.css';
import Word from '../../../../../components/Word';

const Suggest4: React.FC = () => {
  const intl = useIntl();

  // Matthew 4:19 word & address
  const matt419Word = intl.formatMessage({
    id: "fourlaws.suggest.four.words1",
    defaultMessage: "And He said to them, \"Follow Me, and I will make you fishers of men.\""
  });

  const matt419Address = intl.formatMessage({
    id: "fourlaws.suggest.four.words1.address",
    defaultMessage: "(Matthew 4:19)"
  });

  // John 15:8 word & address
  const john158Word = intl.formatMessage({
    id: "fourlaws.suggest.four.words2",
    defaultMessage: "\"My Father is glorified by this, that you bear much fruit, and so prove to be My disciples.\""
  });

  const john158Address = intl.formatMessage({
    id: "fourlaws.suggest.four.words2.address",
    defaultMessage: "(John 15:8)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.suggest.four"
            defaultMessage={'W. Witness for Christ by your life and words.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.suggest.four.address"
            defaultMessage={'(Matthew 4:19; John 15:8)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Suggest4-Matt419" 
              word={matt419Word} 
              address={matt419Address} 
            />,
            <Word
              wordClassName="Law-Suggest4-John158" 
              word={john158Word} 
              address={john158Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/suggest/3 (<Suggest3 />)
        * nextTo: /4laws/suggest/5 (<Suggest5 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_3}`}
        nextTo={`../${FourLawsPaths.INDEX_5}`}
      />
    </React.Fragment>
 
  );
};

export default Suggest4;