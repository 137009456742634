import React from 'react';
import BottomNavigation from '../../../../../components/BottomNavigation';
import { FourLawsPaths } from '../../../../paths';

const SuggestTitle: React.FC = () => {
  return (
    /*
     * previousTo: /4laws/grace/8 (<GraceWrapup3 />)
     * nextTo: /4laws/suggest/1 (<Suggest1 />)
     */
    <BottomNavigation 
      previousTo={`../../${FourLawsPaths.GRACE}/${FourLawsPaths.INDEX_8}`}
      nextTo={`../${FourLawsPaths.INDEX_1}`}
    />
  );
};

export default SuggestTitle;