import { useMediaQuery } from '@mui/material';
import React from 'react';
import logoFull from '../../assets/icons/Logo-full.svg';
import logoMin from '../../assets/icons/Logo-min.svg';
import { IGeneralProps } from '../../interfaces/page.interfaces';

const Logo: React.FC<IGeneralProps> = (props) => {
  const showFull = useMediaQuery('(min-width:1280px)');
  return (
    <img className={props.className} src={(showFull) ? logoFull : logoMin} alt="Logo" />
  );
};

export default Logo;