import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../paths';
import FourLaws from '../FourLaws';

const HomeRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.FOUR_LAWS + '/*'} element={<FourLaws />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.FOUR_LAWS} replace />} />
    </Routes>
  );
};

export default HomeRoutes;
