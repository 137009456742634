import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../../../paths';
import ConfirmFirstQuestion1 from './ConfirmFirstQuestion1';
import ConfirmFirstQuestion2 from './ConfirmFirstQuestion2';
import ConfirmFirstQuestion3 from './ConfirmFirstQuestion3';
import ConfirmFirstQuestion4 from './ConfirmFirstQuestion4';
import ConfirmFirstQuestion5 from './ConfirmFirstQuestion5';
import ConfirmFirstQuestion6 from './ConfirmFirstQuestion6';

const ConfirmFirstQuestionRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<ConfirmFirstQuestion1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<ConfirmFirstQuestion2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<ConfirmFirstQuestion3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<ConfirmFirstQuestion4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<ConfirmFirstQuestion5 />} />
      <Route path={FourLawsPaths.INDEX_6} element={<ConfirmFirstQuestion6 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default ConfirmFirstQuestionRoutes;
