import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import Feelings1 from './Feelings1';
import Feelings2 from './Feelings2';
import Feelings3 from './Feelings3';

const FeelingsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<Feelings1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<Feelings2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<Feelings3 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default FeelingsRoutes;
