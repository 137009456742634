import React from 'react';
import ConfirmSecondRoutes from './ConfirmSecondRoutes';
import './ConfirmSecond.css';

const ConfirmSecond: React.FC = () => {
  return (
    <ConfirmSecondRoutes />
  );
};

export default ConfirmSecond;