import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Suggest5.css';
import Word from '../../../../../components/Word';

const Suggest5: React.FC = () => {
  const intl = useIntl();

  // 1 Peter 5:7 word & address
  const iPet57Word = intl.formatMessage({
    id: "fourlaws.suggest.five.words",
    defaultMessage: "\"casting all your anxiety on Him, because He cares for you.\""
  });

  const iPet57Address = intl.formatMessage({
    id: "fourlaws.suggest.five.words.address",
    defaultMessage: "(1 Peter 5:7)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.suggest.five"
            defaultMessage={'T. Trust God for every detail of your life.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.suggest.five.address"
            defaultMessage={'(1 Peter 5:7)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Suggest5-IPet57" 
              word={iPet57Word} 
              address={iPet57Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/suggest/4 (<Suggest4 />)
        * nextTo: /4laws/suggest/6 (<Suggest6 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_4}`}
        nextTo={`../${FourLawsPaths.INDEX_6}`}
      />
    </React.Fragment>
 
  );
};

export default Suggest5;