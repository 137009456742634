import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FourLawsPaths } from "../../../../../paths";
import ConfirmSecondQuestion1 from "./ConfirmSecondQuestion1";
import ConfirmSecondQuestion2 from "./ConfirmSecondQuestion2";
import ConfirmSecondQuestion3 from "./ConfirmSecondQuestion3";
import ConfirmSecondQuestion4 from "./ConfirmSecondQuestion4";
import ConfirmSecondQuestion5 from "./ConfirmSecondQuestion5";

const ConfirmSecondQuestionRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<ConfirmSecondQuestion1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<ConfirmSecondQuestion2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<ConfirmSecondQuestion3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<ConfirmSecondQuestion4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<ConfirmSecondQuestion5 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default ConfirmSecondQuestionRoutes;