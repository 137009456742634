import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { PageTextType } from '../../interfaces/page.interfaces';
import PageText from '../PageText';
import './Link.css';

export interface IAppLinkProps extends PropsWithChildren {
  to: string;
  onClick?: () => void;
}

const AppLink: React.FC<IAppLinkProps> = (props) => {
  const { to, onClick } = props;
  return (
    <Link className="AppLink" to={to} onClick={onClick}>
      <PageText type={PageTextType.CONTENT}>
        { props.children }
      </PageText>
    </Link>
    
  );
};

export default AppLink;