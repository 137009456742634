import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';

const LawFourGreet0: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Bold">
        <FormattedMessage 
          id="fourlaws.law4.greet.zero"
          defaultMessage={'These two circles represent two kinds of lives:'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/four/5 (<LawFour5 />)
        * nextTo: /4laws/four/greet/1 (<LawFourGreet1 />)
        */}
      <BottomNavigation 
        previousTo={`../../${FourLawsPaths.INDEX_5}`}
        nextTo={`../${FourLawsPaths.INDEX_1}`}
      />
    </React.Fragment>
 
  );
};

export default LawFourGreet0;