import Container from '@mui/material/Container';
import React, { PropsWithChildren } from 'react';
import './PageContainer.css';
import { IGeneralProps } from '../../interfaces/page.interfaces';

const PageContainer: React.FC<PropsWithChildren & IGeneralProps> = (props) => {
  let containerClass = "PageContainer";
  containerClass += (props.className) ? ` ${props.className}`: '';
  return (
    <Container className={containerClass} maxWidth={false}>
      <div className="PageContainer-Wrapper">
        { props.children }
      </div>
    </Container>
  );
};

export default PageContainer;
