import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Answer from '../../../../../../../components/Answer';
import BottomNavigation from '../../../../../../../components/BottomNavigation';
import PageText from '../../../../../../../components/PageText';
import { ConfirmFirstResponseType, PageTextType } from '../../../../../../../interfaces/page.interfaces';
import actionCreators from '../../../../../../../state/actionCreators';
import { FourLawsPaths } from '../../../../../../paths';
import './ConfirmFirstQuestion6.css';

const ConfirmFirstQuestion6: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onNextClick = () => {
    dispatch(actionCreators.setConfirmFirstResponseType(ConfirmFirstResponseType.QUESTION));
  };
  const answer = intl.formatMessage({
    id: "fourlaws.confirm.first.question.six.answer",
    defaultMessage: "In my heart or life"
  });
  return (
    <div className="Law-Question">
      <PageText type={PageTextType.CONTENT} className="Law-PageContent">
        <div className="Law-Sentence-Center Law-Sentence-Bold Law-Confirm1-Question6">
          <FormattedMessage 
            id="fourlaws.confirm.first.question.six"
            defaultMessage="According to His promise in Revelation 3:20, where is Christ right now in relation to you?"
          />
        </div>
        <Answer className="Answer-Confirm-first-question6" answer={answer}></Answer>
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/first/question/5 (<ConfirmFirstQuestion5 />)
        * nextTo: /4laws/confirm/first/2 (<ConfirmFirst2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_5}`}
        nextTo={`../../${FourLawsPaths.INDEX_2}`}
        onNextClick={onNextClick}
      />
    </div>
  );
};

export default ConfirmFirstQuestion6;