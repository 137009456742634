import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawFour1.css';

const LawFour1: React.FC = () => {
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawFour-Content Law-LongSentence LawFour-One"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law4.one.title"
            defaultMessage="We Must Receive Christ"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law4.one.content"
            defaultMessage={'"But as many as received Him, to them He gave the right to become children of God, even to those who believe in His name" (John 1:12)'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/four/0 (<LawFourTitle />)
        * nextTo: /4laws/four/2 (<LawFour2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_0}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default LawFour1;