import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';

const GracePrep2: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center Law-Sentence-Bold">
        <FormattedMessage 
          id="fourlaws.grace.prep.two"
          defaultMessage={'2. Your sins will be forgiven.'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace-prep/3 (<GracePrep1 />)
        * nextTo: /4laws/grace-prep/5 (<GracePrep3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_3}`}
        nextTo={`../${FourLawsPaths.INDEX_5}`}
      />
    </React.Fragment>
  );
};

export default GracePrep2;