import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../paths';
import Confirm from './components/Confirm';
import Feelings from './components/Feelings';
import Grace from './components/Grace';
import GracePrep from './components/GracePrep';
import LawFour from './components/LawFour';
import LawOne from './components/LawOne/LawOne';
import LawThree from './components/LawThree';
import LawTwo from './components/LawTwo';
import Main from './components/Main';
import Prayer from './components/Prayer';
import Suggest from './components/Suggest';
import Wrapup from './components/Wrapup';

const FourLawsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.MAIN + '/*'} element={<Main />} />
      <Route path={FourLawsPaths.LAW_ONE + '/*'} element={<LawOne />} />
      <Route path={FourLawsPaths.LAW_TWO + '/*'} element={<LawTwo />} />
      <Route path={FourLawsPaths.LAW_THREE + '/*'} element={<LawThree />} />
      <Route path={FourLawsPaths.LAW_FOUR + '/*'} element={<LawFour />} />
      <Route path={FourLawsPaths.PRAYER + '/*'} element={<Prayer />} />
      <Route path={FourLawsPaths.CONFIRM + '/*'} element={<Confirm />} />
      <Route path={FourLawsPaths.FEELINGS + '/*'} element={<Feelings />} />
      <Route path={FourLawsPaths.GRACE + '/*'} element={<Grace />} />
      <Route path={FourLawsPaths.GRACE_PREP + '/*'} element={<GracePrep />} />
      <Route path={FourLawsPaths.SUGGEST + '/*'} element={<Suggest />} />
      <Route path={FourLawsPaths.WRAPUP + '/*'} element={<Wrapup />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.MAIN} replace />} />
    </Routes>
  );
};

export default FourLawsRoutes;
