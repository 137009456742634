import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Answer from '../../../../../../../components/Answer';
import BottomNavigation from '../../../../../../../components/BottomNavigation';
import PageText from '../../../../../../../components/PageText';
import { PageTextType } from '../../../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../../../paths';
import './ConfirmSecondQuestion5.css';

const ConfirmSecondQuestion5: React.FC = () => {
  const intl = useIntl();
  const answer = intl.formatMessage({
    id: "fourlaws.confirm.second.question.five.answer",
    defaultMessage: "According to God's promise"
  });
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-PageContent Law-LongSentence">
        <div className="Confirm2-QWords">
          <FormattedMessage 
            id="fourlaws.confirm.second.question.five.words"
            defaultMessage={'"And the witness is this, that God has given us eternal life, ' + 
                            'and this life is in His Son. He who has the Son has the life; ' + 
                            'he who does not have the Son of God does not have the life. ' + 
                            'These things I have written to you who believe in the name of the Son of God, ' + 
                            'in order that you may know that you have eternal life" (<span>1 John 5:11-13</span>).'}
            values={{
              span: (chunks: any) => <span className="Law-Text-Highlight">{chunks}</span>
            }}
          />
        </div>
        <div className="Law-Sentence-Center Law-Question-Below-words No-maxwidth">
          <FormattedMessage 
            id="fourlaws.confirm.second.question.five"
            defaultMessage="How do you know you have the Son?"
          />
        </div>
        <Answer className="Answer-Confirm-second-question5" answer={answer} />
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/second/question/4 (<ConfirmSecondQuestion4 />)
        * nextTo: /4laws/confirm/third/1 (<ConfirmThird1 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_4}`}
        nextTo={`../../../${FourLawsPaths.CONFIRM_THIRD}`}
      />
    </React.Fragment>
  );
};

export default ConfirmSecondQuestion5;