import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import Law4DiagramRight from '../../../../../icons/Law4DiagramRight';
import CircleIcon from '../../../../../icons/CircleIcon';
import CrossIcon from '../../../../../icons/CrossIcon';
import './LawFourGreet2.css';

const LawFourGreet2: React.FC = () => {
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawFour-Greet-Description LawFour-Greet-Two"
      >
        <div className="Law-SubTitle LawFour-SubTitle-MarginLeft">
          <FormattedMessage 
            id="fourlaws.law4.greet.two.title"
            defaultMessage="CHRIST-DIRECTED LIFE"
          />
        </div>
        <div className="LawFour-Greet-Description-flex">
          <div className="LawFour-Greet-DiagramBox">
            <Law4DiagramRight/>
          </div>
          <div className="LawFour-Greet-Content">
            <div className="LawFour-Greet-Row">
              <div className="LawFour-Greet-Row LawFour-Greet-Prefix">
                <CrossIcon className="LawFour-Icon-Inline LawFour-Icon-Cross" />
                {'-'}
              </div>
              <div className="LawFour-Greet-Sentence">
                <FormattedMessage 
                  id="fourlaws.law4.greet.two.line1"
                  defaultMessage={"Christ is in the life and on the throne"}
                />
              </div>
            </div>
            <div className="LawFour-Greet-Row">
              <div className="LawFour-Greet-Row LawFour-Greet-Prefix">
                <span className="Prefix-Span">
                  <FormattedMessage 
                    id="fourlaws.law4.greet.self"
                    defaultMessage="S"
                  />
                </span>
                {'-'}
              </div>
              <div className="LawFour-Greet-Sentence">
                <FormattedMessage 
                  id="fourlaws.law4.greet.two.line2"
                  defaultMessage={"Self is yielding to Christ"}
                />
              </div>
            </div>
            <div className="LawFour-Greet-Row">
              <div className="LawFour-Greet-Row LawFour-Greet-Prefix">
                <CircleIcon className="LawFour-Icon-Inline" />
                {'-'}
              </div>
              <div className="LawFour-Greet-Sentence">
                <FormattedMessage 
                  id="fourlaws.law4.greet.two.line3"
                  defaultMessage={"Interests are directed by Christ, resulting in harmony with God's plan."}
                />
              </div>
            </div>
          </div>
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/four/greet/1 (<LawFourGreet1 />)
        * nextTo: /4laws/four/greet/3 (<LawFourGreet3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default LawFourGreet2;