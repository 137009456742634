import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import Suggest1 from './Suggest1';
import Suggest2 from './Suggest2';
import Suggest3 from './Suggest3';
import Suggest4 from './Suggest4';
import Suggest5 from './Suggest5';
import Suggest6 from './Suggest6';
import SuggestTitle from './SuggestTitle';

const SuggestRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<SuggestTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<Suggest1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<Suggest2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<Suggest3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<Suggest4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<Suggest5 />} />
      <Route path={FourLawsPaths.INDEX_6} element={<Suggest6 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default SuggestRoutes;
