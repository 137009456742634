import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Answer from '../../../../../../../components/Answer';
import BottomNavigation from '../../../../../../../components/BottomNavigation';
import PageText from '../../../../../../../components/PageText';
import { PageTextType } from '../../../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../../../paths';
import './ConfirmFirstQuestion4.css';

const ConfirmFirstQuestion4: React.FC = () => {
  const intl = useIntl();
  const answer = intl.formatMessage({
    id: "fourlaws.confirm.first.question.four.answer",
    defaultMessage: "To come into our lives"
  });
  return (
    <div className="Law-Question">
      <PageText type={PageTextType.CONTENT} className="Law-PageContent Law-LongSentence">
        <div className="Law-Sentence-Margin Confirm1-QWords">
          <FormattedMessage 
            id="fourlaws.confirm.first.question.four.words"
            defaultMessage={'(Christ speaking): "Behold, I stand at the door and knock; ' + 
                            'if anyone hears My voice and opens the door, <span>I will come in to him</span>" (Revelation 3:20).'}
            values={{
              span: (chunks: any) => <span className="Law-Text-Highlight">{chunks}</span>
            }}
          />
        </div>
        <div className="Law-Sentence-Center Law-Question-Below-words">
          <FormattedMessage 
            id="fourlaws.confirm.first.question.four"
            defaultMessage="What is Christ's responsibility?"
          />
        </div>
        <Answer className="Answer-Confirm-first-question4" answer={answer} />
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/first/question/3 (<ConfirmFirstQuestion3 />)
        * nextTo: /4laws/confirm/first/question/5 (<ConfirmFirstQuestion5 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_3}`}
        nextTo={`../${FourLawsPaths.INDEX_5}`}
      />
    </div>
  );
};

export default ConfirmFirstQuestion4;