import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FourLawsPaths } from "../../../../paths";
import ConfirmSecond1 from "./ConfirmSecond1";
import ConfirmSecondQuestion from "./ConfirmSecondQuestion";

const ConfirmSecondRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<ConfirmSecond1 />} />
      <Route path={FourLawsPaths.CONFIRM_QUESTION + '/*'} element={<ConfirmSecondQuestion />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default ConfirmSecondRoutes;