import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType, PageType } from '../../../../../interfaces/page.interfaces';
import { AppState } from '../../../../../state/reducer';
import { FourLawsPaths } from '../../../../paths';
import './LawFourWrapup.css';

const LawFourWrapup: React.FC = () => {
  const greetFirstAnswer: PageType | undefined = useSelector((state: AppState) => state.greetFirstAnswer);
  const isReview: boolean = greetFirstAnswer === PageType.REVIEW;
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="LawFour-Wrapup Law-Sentence-Bold Law-Sentence-Center">
        <FormattedMessage 
          id="fourlaws.law4.wrapup"
          defaultMessage={'The following explains how you can receive Christ:'}
        />
      </PageText>
      {/* 
        * previousTo(isReview=true): /4laws/four/8 (<LawFourWrapupReview />)
        * previousTo(isReview=false): /4laws/four/greet/4 (<LawFourGreet4 />)
        * nextTo: /4laws/prayer/1 (<Prayer1 />)
        */}
      <BottomNavigation 
        previousTo={(isReview) ? `../${FourLawsPaths.INDEX_8}` : `../${FourLawsPaths.GREET}/${FourLawsPaths.INDEX_4}`}
        nextTo={`../../${FourLawsPaths.PRAYER}`}
      />
    </React.Fragment>
 
  );
};

export default LawFourWrapup;