import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Suggest3.css';
import Word from '../../../../../components/Word';

const Suggest3: React.FC = () => {
  const intl = useIntl();

  // John 14:21 word & address
  const john1421Word = intl.formatMessage({
    id: "fourlaws.suggest.three.words",
    defaultMessage: "\"He who has My commandments and keeps them is the one who loves Me; and he who loves Me will be loved by My Father, " + 
                    "and I will love him and will disclose Myself to him.\""
  });

  const john1421Address = intl.formatMessage({
    id: "fourlaws.suggest.three.words.address",
    defaultMessage: "(John 14:21)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.suggest.three"
            defaultMessage={'O. Obey God, moment by moment.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.suggest.three.address"
            defaultMessage={'(John 14:21)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Suggest3-John1421" 
              word={john1421Word} 
              address={john1421Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/suggest/2 (<Suggest2 />)
        * nextTo: /4laws/suggest/4 (<Suggest4 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
        nextTo={`../${FourLawsPaths.INDEX_4}`}
      />
    </React.Fragment>
 
  );
};

export default Suggest3;