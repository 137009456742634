import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import Grace1 from './Grace1';
import Grace2 from './Grace2';
import Grace3 from './Grace3';
import Grace4 from './Grace4';
import Grace5 from './Grace5';
import GraceTitle from './GraceTitle';
import GraceWrapup1 from './GraceWrapup1';
import GraceWrapup2 from './GraceWrapup2';
import GraceWrapup3 from './GraceWrapup3';

const GraceRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<GraceTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<Grace1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<Grace2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<Grace3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<Grace4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<Grace5 />} />
      <Route path={FourLawsPaths.INDEX_6} element={<GraceWrapup1 />} />
      <Route path={FourLawsPaths.INDEX_7} element={<GraceWrapup2 />} />
      <Route path={FourLawsPaths.INDEX_8} element={<GraceWrapup3 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default GraceRoutes;
