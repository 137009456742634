import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawThree1.css';

const LawThree1: React.FC = () => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawThree-Content Law-LongSentence LawThree-One"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law3.one.title"
            defaultMessage="He Died in Our Place"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law3.one.content"
            defaultMessage={'"But God demonstrates His own love toward us, in that while we were yet sinners, Christ died for us" (Romans 5:8).'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/three/0 (<LawThreeTitle />)
        * nextTo: /4laws/three/2 (<LawThree2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_0}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default LawThree1;