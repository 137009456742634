import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import locale_en from '../../assets/i18n/en.json';
import locale_ko_RR from '../../assets/i18n/ko-RR.json';
import locale_ko_NT from '../../assets/i18n/ko-NT.json';
import { Language, LanguageName } from '../../interfaces/language.interfaces';
import { FourLawsPaths } from '../paths';
import Header from '../Header';
import HomeRoutes from './HomeRoutes';
import actionCreators from '../../state/actionCreators';

const translations: { [k: string]: any } = {
  [Language.en]: locale_en,
  [Language.koRR]: locale_ko_RR,
  [Language.koNT]: locale_ko_NT
};

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch(FourLawsPaths.LOCALE + '/*');
  let locale = (match && match.params && match.params.locale) ? match.params.locale: Language.en;

  const [currentLocale, setCurrentLocale] = useState(locale);
  if (locale !== currentLocale) {
    setCurrentLocale(locale);
  }

  //correct rerendering order (react lifecycle) by wrapping dispatch in useEffect 
  useEffect(() => {
    dispatch(actionCreators.setLanguage((currentLocale === Language.en) ? LanguageName.ENGLISH: LanguageName.KOREAN));
  });

  const changeLanguage = (language: Language) => {
    setCurrentLocale(language);
    navigate(location.pathname.replace(/(\/[\w|-]+)(\/.*)/, `/${language}$2`));
  };

  // go to default root path when the given locale is not supported.
  if (!Object.keys(translations).includes(locale)) {
    navigate('/', { replace: true });
  }

  return (
    <IntlProvider key={currentLocale} locale={currentLocale} messages={translations[currentLocale]} defaultLocale={Language.en}>
      <Header locale={currentLocale} changeLanguage={changeLanguage}></Header>
      <HomeRoutes></HomeRoutes>
    </IntlProvider>
  );
};

export default Home;