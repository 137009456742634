import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './GracePrepMain1.css';

const GracePrepMain1: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center Law-Sentence-Bold Law-GracePrepMain1">
        <FormattedMessage 
          id="fourlaws.grace.prep.main.one"
          defaultMessage={'I would like to tell you what will happen if you receive Christ.'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/prayer/3 (<Prayer3 />)
        * nextTo: /4laws/grace-prep/2 (<GracePrepMain2 />)
        */}
      <BottomNavigation 
        previousTo={`../../${FourLawsPaths.PRAYER}/${FourLawsPaths.INDEX_3}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
  );
};

export default GracePrepMain1;