import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawThree3.css';

const LawThree3: React.FC = () => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawThree-Content Law-LongSentence LawThree-Three"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law3.three.title"
            defaultMessage="He is the Only Way to God"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law3.three.content"
            defaultMessage={'"Jesus said to him, \'I am the way, the truth, and the life; no one comes to the Father, but through Me\'" (John 14:6).'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/three/2 (<LawThree2 />)
        * nextTo: /4laws/three/4 (<LawThree4 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
        nextTo={`../${FourLawsPaths.INDEX_4}`}
      />
    </React.Fragment>
  );
};

export default LawThree3;