import React from 'react';
import './App.css';
import { HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppRoutes from './AppRoutes';

const THEME = createTheme({
  typography: {
    "fontFamily": `"Ikkeullim", "Helvetica", "Arial", "sans-serif"`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette: {
    primary: { main: '#FFFFFF' }
  }
});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={THEME}>
        <HashRouter basename="/">
          <AppRoutes />
        </HashRouter>
      </ThemeProvider>
    );
  }
}

export default App;
