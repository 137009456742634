import React from 'react';
import { IGeneralProps, PageTextType } from '../../interfaces/page.interfaces';
import PageText from '../PageText';
import Answer from '../Answer';

export interface IQuestionProps extends IGeneralProps {
  question: string;
  qClassName?: string;
  answer: string;
  aClassName?: string;
}

const Question: React.FC<IQuestionProps> = (props) => {
  let qClassName = "Law-Sentence-Center Law-Sentence-Margin Law-Sentence-Bold";
  qClassName += (props.qClassName) ? ` ${props.qClassName}`: '';

  return (
    <PageText type={PageTextType.CONTENT} className="Law-PageContent">
      <div className={qClassName}>
        { props.question }
      </div>
      <Answer 
        className={props.aClassName} 
        answer={props.answer} 
      />
    </PageText>
  );
};

export default Question;