import React from 'react';
import BottomNavigation from '../../../../../components/BottomNavigation';
import { FourLawsPaths } from '../../../../paths';

const LawFourTitle: React.FC = () => {
  return (
    /*
     * previousTo: /4laws/three/5 (<LawThreeWrapup />)
     * nextTo: /4laws/four/1 (<LawFour1 />)
     */
    <BottomNavigation 
      previousTo={`../../${FourLawsPaths.LAW_THREE}/${FourLawsPaths.INDEX_5}`}
      nextTo={`../${FourLawsPaths.INDEX_1}`}
    />
  );
};

export default LawFourTitle;