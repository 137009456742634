import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType, PageType } from '../../../../../interfaces/page.interfaces';
import actionCreators from '../../../../../state/actionCreators';
import { FourLawsPaths } from '../../../../paths';
import './PrayerReview2.css';

const PrayerReview2: React.FC = () => {
  const dispatch = useDispatch();
  const onNextClick = () => {
    dispatch(actionCreators.setGreetFirstAnswer(PageType.REVIEW));
  };

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Prayer-Review2">
        <div className="Law-Sentence-Center Law-Sentence-Margin Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.prayer.review.two"
            defaultMessage={'Can you explain to me when and how you accepted Jesus Christ as your Savior and Lord?'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/prayer/5 (<PrayerReview1 />)
        * nextTo: /4laws/confirm/0 (<ConfirmMain />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_5}`}
        nextTo={`../../${FourLawsPaths.CONFIRM}`}
        onNextClick={onNextClick}
      />
    </React.Fragment>
  );
};

export default PrayerReview2;