import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './GracePrepMain2.css';

const GracePrepMain2: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center Law-Sentence-Bold Law-GracePrepMain2">
        <FormattedMessage 
          id="fourlaws.grace.prep.main.two"
          defaultMessage={'If you receive Christ by faith, as an act of the will, many things will happen, including the following:'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace-prep/1 (<GracePrepMain1 />)
        * nextTo: /4laws/grace-prep/3 (<GracePrep1 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default GracePrepMain2;