import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FourLawsPaths } from "../../../../paths";
import ConfirmThird1 from "./ConfirmThird1";
import ConfirmThird2 from "./ConfirmThird2";
import ConfirmThirdQuestion from "./ConfirmThirdQuestion";

const ConfirmThirdRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<ConfirmThird1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<ConfirmThird2 />} />
      <Route path={FourLawsPaths.CONFIRM_QUESTION + '/*'} element={<ConfirmThirdQuestion />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default ConfirmThirdRoutes;