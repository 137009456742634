import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import MainTitle from './MainTitle';
import MainWrapup from './MainWrapup';

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<MainTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<MainWrapup />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default MainRoutes;
