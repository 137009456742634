import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from "../../../paths";
import LawTwo1 from "./LawTwo1";
import LawTwo2 from "./LawTwo2";
import LawTwo3 from './LawTwo3';
import LawTwo4 from './LawTwo4';
import LawTwoTitle from "./LawTwoTitle";
import LawTwoWrapup from './LawTwoWrapup';

const LawTwoRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<LawTwoTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<LawTwo1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<LawTwo2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<LawTwo3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<LawTwo4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<LawTwoWrapup />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default LawTwoRoutes;
