import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Grace5.css';
import Word from '../../../../../components/Word';

const Grace5: React.FC = () => {
  const intl = useIntl();

  // John 10:10 word & address
  const john1010Word = intl.formatMessage({
    id: "fourlaws.grace.five.words1",
    defaultMessage: "\"The thief comes only to steal and kill and destroy; I came that they may have life, and have it abundantly.\""
  });

  const john1010Address = intl.formatMessage({
    id: "fourlaws.grace.five.words1.address",
    defaultMessage: "(John 10:10)"
  });

  // 2 Cor 5:17 word & address
  const iiCor517Word = intl.formatMessage({
    id: "fourlaws.grace.five.words2",
    defaultMessage: "\"Therefore if anyone is in Christ, he is a new creature; the old things passed away; behold, new things have come.\""
  });

  const iiCor517Address = intl.formatMessage({
    id: "fourlaws.grace.five.words2.address",
    defaultMessage: "(2 Corinthians 5:17)"
  });
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.grace.five"
            defaultMessage={'5. You began the great adventure for which God created you.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.grace.five.address"
            defaultMessage={'(John 10:10; 2 Corinthians 5:17)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Grace5-John1010" 
              word={john1010Word} 
              address={john1010Address} 
            />, 
            <Word
              wordClassName="Law-Grace5-IICor517" 
              word={iiCor517Word} 
              address={iiCor517Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace/4 (<Grace4 />)
        * nextTo: /4laws/grace/6 (<GraceWrapup1 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_4}`}
        nextTo={`../${FourLawsPaths.INDEX_6}`}
      />
    </React.Fragment>
 
  );
};

export default Grace5;