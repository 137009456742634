import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';

const GracePrep4: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center Law-Sentence-Bold">
        <FormattedMessage 
          id="fourlaws.grace.prep.four"
          defaultMessage={'4. You will receive eternal life.'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace-prep/5 (<GracePrep3 />)
        * nextTo: /4laws/grace-prep/7 (<GracePrep5 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_5}`}
        nextTo={`../${FourLawsPaths.INDEX_7}`}
      />
    </React.Fragment>
  );
};

export default GracePrep4;