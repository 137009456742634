import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './GraceWrapup1.css';
import Word from '../../../../../components/Word';

const GraceWrapup1: React.FC = () => {
  const intl = useIntl();

  // 1 Thess 5:18 word & address
  const iThess518Word = intl.formatMessage({
    id: "fourlaws.grace.wrapup1.words",
    defaultMessage: "\"in everything give thanks; for this is God's will for you in Christ Jesus.\""
  });

  const iThess518Address = intl.formatMessage({
    id: "fourlaws.grace.wrapup1.words.address",
    defaultMessage: "(1 Thessalonians 5:18)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-GraceWrapup1">
          <FormattedMessage 
            id="fourlaws.grace.wrapup.one"
            defaultMessage={'Can you think of anything more wonderful that could happen to you than receiving Christ? ' + 
                            'Would you like to thank God in prayer right now for what He has done for you? ' + 
                            'The very act of thanking God demonstrates faith.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.grace.wrapup.one.address"
            defaultMessage={'(1 Thessalonians 5:18)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-GraceWrapup1-IThess518" 
              word={iThess518Word} 
              address={iThess518Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace/5 (<Grace5 />)
        * nextTo: /4laws/grace/7 (<GraceWrapup2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_5}`}
        nextTo={`../${FourLawsPaths.INDEX_7}`}
      />
    </React.Fragment>
 
  );
};

export default GraceWrapup1;