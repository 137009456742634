import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import './DiagramLink.css';
import { IGeneralProps } from '../../interfaces/page.interfaces';

export interface IDiagramLinkProps extends PropsWithChildren, IGeneralProps {
  to: string;
  onClick?: () => void;
}

const DiagramLink: React.FC<IDiagramLinkProps> = (props) => {
  const { to, onClick } = props;
  let className = "DiagramLink";
  if (props.className) className += ' ' + props.className;
  return (
    <Link to={to} className={className} onClick={onClick}>
      { props.children }
    </Link>
  );
};

export default DiagramLink;