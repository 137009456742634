import React from 'react';
import './FourLaws.css';
import PageContainer from '../../components/PageContainer';
import FourLawsRoutes from './FourLawsRoutes';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducer';

const FourLaws: React.FC = () => {
  const language = useSelector((state: AppState) => state.language);
  const intl = useIntl();
  const containerClass = `Language-${language} Locale-${intl.locale}`;
  return (
    <PageContainer className={containerClass}>
      <FourLawsRoutes />
    </PageContainer>
  );
};

export default FourLaws;
