import React from 'react';
import BottomNavigation from '../../../../../components/BottomNavigation';
import { FourLawsPaths } from '../../../../paths';

const GraceTitle: React.FC = () => {
  return (
    /*
     * previousTo: /4laws/feelings/3 (<Feelings3 />)
     * nextTo: /4laws/grace/1 (<Grace1 />)
     */
    <BottomNavigation 
      previousTo={`../../${FourLawsPaths.FEELINGS}/${FourLawsPaths.INDEX_3}`}
      nextTo={`../${FourLawsPaths.INDEX_1}`}
    />
  );
};

export default GraceTitle;