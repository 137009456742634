import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from "../../../paths";
import LawThree1 from "./LawThree1";
import LawThree2 from "./LawThree2";
import LawThree3 from './LawThree3';
import LawThree4 from './LawThree4';
import LawThreeTitle from "./LawThreeTitle";
import LawThreeWrapup from './LawThreeWrapup';

const LawThreeRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<LawThreeTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<LawThree1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<LawThree2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<LawThree3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<LawThree4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<LawThreeWrapup />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default LawThreeRoutes;
