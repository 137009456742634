import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Grace3.css';
import Word from '../../../../../components/Word';
import Question from '../../../../../components/Question';

const Grace3: React.FC = () => {
  const [minimized, setMinimized] = useState(false); 
  const onNavigateClick = (index: number) => {
    setMinimized(index !== 0);
  };

  // class for minimizing word(s) when question is displayed.
  let className = "Law-Dialog-Title";
  className += (minimized) ? ' Law-Dialog-Title-Minimized' : '';  
  
  const intl = useIntl();

  // John 1:12 word & address and two Q & A 's
  const john112Word = intl.formatMessage({
    id: "fourlaws.grace.three.words",
    defaultMessage: "\"But as many as received Him, to them He gave the right to become children of God, even to those who believe in His name\""
  });

  const john112Address = intl.formatMessage({
    id: "fourlaws.grace.three.words.address",
    defaultMessage: "(John 1:12)"
  });

  const question1 = intl.formatMessage({
    id: "fourlaws.grace.three.question1",
    defaultMessage: "What does this verse say you have become?"
  });
  const answer1 = intl.formatMessage({
    id: "fourlaws.grace.three.question1.answer",
    defaultMessage: "Child of God"
  });

  const question2 = intl.formatMessage({
    id: "fourlaws.grace.three.question2",
    defaultMessage: "If you are Child of God and I am a child of God, what does that make us?"
  });
  const answer2 = intl.formatMessage({
    id: "fourlaws.grace.three.question2.answer",
    defaultMessage: "Brother or Sister"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.grace.three"
            defaultMessage={'3. You became a child of God.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.grace.three.address"
            defaultMessage={'(John 1:12)'}
          />
        </div>
        <Dialog 
          contents={[
            <div></div>, 
            <Question
              question={question1}
              answer={answer1}
              aClassName="Answer-Grace3-Q1"
            />, 
            <Question
              question={question2}
              qClassName="Law-Grace3-Q2"
              answer={answer2}
              aClassName="Answer-Grace3-Q2"
            />
          ]} 
          title={
            <Word 
              className={className}
              wordClassName="Law-Grace3-John112" 
              word={john112Word} 
              address={john112Address} 
            />
          }
          onNavigateClick={onNavigateClick}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace/2 (<Grace2 />)
        * nextTo: /4laws/grace/4 (<Grace4 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
        nextTo={`../${FourLawsPaths.INDEX_4}`}
      />
    </React.Fragment>
 
  );
};

export default Grace3;