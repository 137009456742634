import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Grace1.css';
import Word from '../../../../../components/Word';

const Grace1: React.FC = () => {
  const intl = useIntl();

  // Rev 3:20 word & address
  const rev320Word = intl.formatMessage({
    id: "fourlaws.grace.one.words1",
    defaultMessage: "\"Behold, I stand at the door and knock; if anyone hears My voice and opens the door, I will come in to him and will dine with him, and he with Me.\""
  });

  const rev320Address = intl.formatMessage({
    id: "fourlaws.grace.one.words1.address",
    defaultMessage: "(Revelation 3:20)"
  });

  // Col 1:27 word & address
  const col127Word = intl.formatMessage({
    id: "fourlaws.grace.one.words2",
    defaultMessage: "\"to whom God willed to make known what is the riches of the glory of this mystery among the Gentiles, which is Christ in you, the hope of glory.\""
  });

  const col127Address = intl.formatMessage({
    id: "fourlaws.grace.one.words2.address",
    defaultMessage: "(Colossians 1:27)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.grace.one"
            defaultMessage={'1. Christ came into your life.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.grace.one.address"
            defaultMessage={'(Revelation 3:20; Colossians 1:27)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Grace1-Rev320" 
              word={rev320Word} 
              address={rev320Address} 
            />, 
            <Word
              wordClassName="Law-Grace1-Col127" 
              word={col127Word} 
              address={col127Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace/0 (<GraceTitle />)
        * nextTo: /4laws/grace/2 (<Grace2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_0}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
 
  );
};

export default Grace1;