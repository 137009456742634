import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FourLawsPaths } from "../../../../paths";
import ConfirmFirst1 from "./ConfirmFirst1";
import ConfirmFirst2 from "./ConfirmFirst2";
import ConfirmFirstQuestion from "./ConfirmFirstQuestion";

const ConfirmFirstRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<ConfirmFirst1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<ConfirmFirst2 />} />
      <Route path={FourLawsPaths.CONFIRM_QUESTION + '/*'} element={<ConfirmFirstQuestion />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} />} />
    </Routes>
  );
};

export default ConfirmFirstRoutes;