import React from 'react';
import BottomNavigation from '../../../../../components/BottomNavigation';
import { FourLawsPaths } from '../../../../paths';

const LawOneTitle: React.FC = () => {
  return (
    /*
     * previousTo: /4laws/main/1 (<MainWrapup />)
     * nextTo: /4laws/one/1 (<LawOne1 />)
     */
    <BottomNavigation 
      previousTo={`../../${FourLawsPaths.MAIN}/${FourLawsPaths.INDEX_1}`}
      nextTo={`../${FourLawsPaths.INDEX_1}`}
    />
  );
};

export default LawOneTitle;