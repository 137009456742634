import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Suggest6.css';
import Word from '../../../../../components/Word';

const Suggest6: React.FC = () => {
  const intl = useIntl();

  // Galatians 5:16,17 word & address
  const gal51617Word = intl.formatMessage({
    id: "fourlaws.suggest.six.words1",
    defaultMessage: "\"But I say, walk by the Spirit, and you will not carry out the desire of the flesh. " + 
                    "For the flesh sets its desire against the Spirit, and the Spirit against the flesh; " + 
                    "for these are in opposition to one another, so that you may not do the things that you please.\""
  });

  const gal51617Address = intl.formatMessage({
    id: "fourlaws.suggest.six.words1.address",
    defaultMessage: "(Galatians 5:16,17)"
  });

  // Acts 1:8 word & address
  const acts18Word = intl.formatMessage({
    id: "fourlaws.suggest.six.words2",
    defaultMessage: "\"but you will receive power when the Holy Spirit has come upon you; and you shall be My witnesses both in Jerusalem, " + 
                    "and in all Judea and Samaria, and even to the remotest part of the earth.\""
  });

  const acts18Address = intl.formatMessage({
    id: "fourlaws.suggest.six.words2.address",
    defaultMessage: "(Acts 1:8)"
  });
  
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold Law-Suggest6-Title">
          <FormattedMessage 
            id="fourlaws.suggest.six"
            defaultMessage={'H. Holy Spirit - Allow Him to control and empower your daily life and witness.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.suggest.six.address"
            defaultMessage={'(Galatians 5:16,17; Acts 1:8)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Suggest6-Gal51617" 
              word={gal51617Word} 
              address={gal51617Address} 
            />,
            <Word
              wordClassName="Law-Suggest6-Acts18" 
              word={acts18Word} 
              address={acts18Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/suggest/5 (<Suggest5 />)
        * nextTo: /4laws/wrapup/1 (<Wrapup1 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_5}`}
        nextTo={`../../${FourLawsPaths.WRAPUP}`}
      />
    </React.Fragment>
 
  );
};

export default Suggest6;