import React from 'react';
import { IGeneralProps, PageTextType } from '../../interfaces/page.interfaces';
import PageText from '../PageText';

export interface IWordProps extends IGeneralProps {
  word: string;
  wordClassName?: string;
  address: string;
}

const Word: React.FC<IWordProps> = (props) => {
  let className = "Law-PageContent";
  className += (props.className) ? ` ${props.className}`: '';
  let wordClassName = "Law-Sentence-Center Law-Sentence-Bold";
  wordClassName += (props.wordClassName) ? ` ${props.wordClassName}`: '';
  return (
    <PageText type={PageTextType.CONTENT} className={className}>
      <div className={wordClassName}>
        { props.word }
      </div>
      <div className="Law-Sentence-Center">
        { props.address }
      </div>
    </PageText>
  );
};

export default Word;