import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Answer from '../../../../../../../components/Answer';
import BottomNavigation from '../../../../../../../components/BottomNavigation';
import PageText from '../../../../../../../components/PageText';
import { PageTextType } from '../../../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../../../paths';
import './ConfirmSecondQuestion4.css';

const ConfirmSecondQuestion4: React.FC = () => {
  const intl = useIntl();
  const answer = intl.formatMessage({
    id: "fourlaws.confirm.second.question.four.answer",
    defaultMessage: "Eternal life"
  });
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-PageContent Law-LongSentence">
        <div className="Confirm2-QWords">
          <FormattedMessage 
            id="fourlaws.confirm.second.question.four.words"
            defaultMessage={'"And the witness is this, that God has given us eternal life, ' + 
                            'and this life is in His Son. He who has the Son has the life; ' + 
                            'he who does not have the Son of God does not have the life. ' + 
                            'These things I have written to you who believe in the name of the Son of God, ' + 
                            '<span>in order that you may know that you have eternal life</span>" (1 John 5:11-13).'}
            values={{
              span: (chunks: any) => <span className="Law-Text-Highlight">{chunks}</span>
            }}
          />
        </div>
        <div className="Law-Sentence-Center Law-Question-Below-words Law-ConfirmSecond-Q4-Title">
          <FormattedMessage 
            id="fourlaws.confirm.second.question.four"
            defaultMessage="If you have the Son what else do you have?"
          />
        </div>
        <Answer className="Answer-Confirm-second-question4" answer={answer} />
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/second/question/3 (<ConfirmSecondQuestion3 />)
        * nextTo: /4laws/confirm/second/question/5 (<ConfirmSecondQuestion5 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_3}`}
        nextTo={`../${FourLawsPaths.INDEX_5}`}
      />
    </React.Fragment>
  );
};

export default ConfirmSecondQuestion4;