import React from 'react';
import koreanIcon from '../../assets/icons/Law3Icon-ko.svg';
import englishIcon from '../../assets/icons/Law3Icon-en.svg';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducer';
import { IGeneralProps } from '../../interfaces/page.interfaces';
import { LanguageName } from '../../interfaces/language.interfaces';

const Law3Icon: React.FC<IGeneralProps> = (props) => {
  const language = useSelector((state: AppState) => state.language);
  return (
    (language === LanguageName.KOREAN) ? 
      <img className={props.className} src={koreanIcon} alt='Law3Icon' /> : 
      <img className={props.className} src={englishIcon} alt='Law3Icon' />
  );
};

export default Law3Icon;