import React from 'react';
import circleIcon from '../../assets/icons/CircleIcon.svg';
import { IGeneralProps } from '../../interfaces/page.interfaces';

const CircleIcon: React.FC<IGeneralProps> = (props) => {
  return (
    <img className={props.className} src={circleIcon} alt='CircleIcon' />
  );
};

export default CircleIcon;