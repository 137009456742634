import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import TrainDiagram from '../../../../../icons/TrainDiagram';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';

const Feelings2: React.FC = () => {
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="Law-PageContent Law-LongSentence Law-Feelings-Two"
      >
        <div>
          <FormattedMessage 
            id="fourlaws.feelings.two"
            defaultMessage={'This train diagram illustrates the relationship between <b>fact</b> (God and His Word), ' + 
                            '<b>faith</b> (our trust in God and His Word), and <b>feeling</b> (the result of our faith and obedience) (John 14:21).'}
            values={{
              b: (chunks : any) => <b>{chunks}</b>
            }}
          />
        </div>
      </PageText>
      <div className="Train-DiagramBox">
        <TrainDiagram />
      </div>
      {/* 
        * previousTo: /4laws/feelings/1 (<Feelings1 />)
        * nextTo: /4laws/feelings/3 (<Feelings3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default Feelings2;