import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Suggest1.css';
import Word from '../../../../../components/Word';

const Suggest1: React.FC = () => {
  const intl = useIntl();

  // John 15:7 word & address
  const john157Word = intl.formatMessage({
    id: "fourlaws.suggest.one.words",
    defaultMessage: "\"If you abide in Me, and My words abide in you, ask whatever you wish, and it will be done for you.\""
  });

  const john157Address = intl.formatMessage({
    id: "fourlaws.suggest.one.words.address",
    defaultMessage: "(John 15:7)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.suggest.one"
            defaultMessage={'G. Go to God in prayer daily.'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.suggest.one.address"
            defaultMessage={'(John 15:7)'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Suggest1-John157" 
              word={john157Word} 
              address={john157Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/suggest/0 (<SuggestTitle />)
        * nextTo: /4laws/suggest/2 (<Suggest2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_0}`}
        nextTo={`../${FourLawsPaths.INDEX_2}`}
      />
    </React.Fragment>
 
  );
};

export default Suggest1;