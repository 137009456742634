import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import GracePrep1 from './GracePrep1';
import GracePrep2 from './GracePrep2';
import GracePrep3 from './GracePrep3';
import GracePrep4 from './GracePrep4';
import GracePrep5 from './GracePrep5';
import GracePrepMain1 from './GracePrepMain1';
import GracePrepMain2 from './GracePrepMain2';
import GracePrepWrapup1 from './GracePrepWrapup1';
import GracePrepWrapup2 from './GracePrepWrapup2';

const GracePrepRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<GracePrepMain1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<GracePrepMain2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<GracePrep1 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<GracePrep2 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<GracePrep3 />} />
      <Route path={FourLawsPaths.INDEX_6} element={<GracePrep4 />} />
      <Route path={FourLawsPaths.INDEX_7} element={<GracePrep5 />} />
      <Route path={FourLawsPaths.INDEX_8} element={<GracePrepWrapup1 />} />
      <Route path={FourLawsPaths.INDEX_9} element={<GracePrepWrapup2 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default GracePrepRoutes;
