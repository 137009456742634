import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import Dialog from '../../../../../components/Dialog';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './Suggest2.css';
import Word from '../../../../../components/Word';

const Suggest2: React.FC = () => {
  const intl = useIntl();

  // Acts 17:11 word & address
  const acts1711Word = intl.formatMessage({
    id: "fourlaws.suggest.two.words",
    defaultMessage: "\"Now these were more noble-minded than those in Thessalonica, for they received the word with great eagerness, " + 
                    "examining the Scriptures daily to see whether these things were so.\""
  });

  const acts1711Address = intl.formatMessage({
    id: "fourlaws.suggest.two.words.address",
    defaultMessage: "(Acts 17:11)"
  });

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center">
        <div className="Law-Sentence-Bold">
          <FormattedMessage 
            id="fourlaws.suggest.two"
            defaultMessage={'R. Read God\'s Word daily.'}
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.suggest.two.address"
            defaultMessage={'(Acts 17:11)'}
          />
        </div>
        <div className="Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.suggest.two.suggestion"
            defaultMessage={'Begin with the Gospel of John.'}
          />
        </div>
        <Dialog 
          contents={[
            <Word
              wordClassName="Law-Suggest2-Acts1711" 
              word={acts1711Word} 
              address={acts1711Address} 
            />
          ]} 
        />
      </PageText>
      {/* 
        * previousTo: /4laws/suggest/1 (<Suggest1 />)
        * nextTo: /4laws/suggest/3 (<Suggest3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
 
  );
};

export default Suggest2;