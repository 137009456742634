import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from "../../../paths";
import LawFour1 from './LawFour1';
import LawFour2 from './LawFour2';
import LawFour3 from './LawFour3';
import LawFour4 from './LawFour4';
import LawFour5 from './LawFour5';
import LawFourGreet0 from './LawFourGreet0';
import LawFourGreet1 from './LawFourGreet1';
import LawFourGreet2 from './LawFourGreet2';
import LawFourGreet3 from './LawFourGreet3';
import LawFourGreet4 from './LawFourGreet4';
import LawFourTitle from './LawFourTitle';
import LawFourWrapup from './LawFourWrapup';
import LawFourWrapupPrep from './LawFourWrapupPrep';
import LawFourWrapupReview from './LawFourWrapupReview';

const LawFourRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<LawFourTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<LawFour1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<LawFour2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<LawFour3 />} />
      <Route path={FourLawsPaths.INDEX_4} element={<LawFour4 />} />
      <Route path={FourLawsPaths.INDEX_5} element={<LawFour5 />} />
      <Route path={FourLawsPaths.GREET} element={<Outlet />}>
        <Route path={FourLawsPaths.INDEX_0} element={<LawFourGreet0 />} />
        <Route path={FourLawsPaths.INDEX_1} element={<LawFourGreet1 />} />
        <Route path={FourLawsPaths.INDEX_2} element={<LawFourGreet2 />} />
        <Route path={FourLawsPaths.INDEX_3} element={<LawFourGreet3 />} />
        <Route path={FourLawsPaths.INDEX_4} element={<LawFourGreet4 />} />
      </Route>
      <Route path={FourLawsPaths.INDEX_6} element={<LawFourWrapup />} />
      <Route path={FourLawsPaths.INDEX_7} element={<LawFourWrapupPrep />} />
      <Route path={FourLawsPaths.INDEX_8} element={<LawFourWrapupReview />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default LawFourRoutes;
