import React from 'react';
import BottomNavigation from '../../../../../components/BottomNavigation';
import { FourLawsPaths } from '../../../../paths';

const LawTwoTitle: React.FC = () => {
  return (
    /*
     * previousTo: /4laws/one/3 (<LawOneWrapup />)
     * nextTo: /4laws/two/1 (<LawTwo1 />)
     */
    <BottomNavigation 
      previousTo={`../../${FourLawsPaths.LAW_ONE}/${FourLawsPaths.INDEX_3}`}
      nextTo={`../${FourLawsPaths.INDEX_1}`}
    />
  );
};

export default LawTwoTitle;