import React from 'react';
import ConfirmThirdRoutes from './ConfirmThirdRoutes';
import './ConfirmThird.css';

const ConfirmThird: React.FC = () => {
  return (
    <ConfirmThirdRoutes />
  );
};

export default ConfirmThird;