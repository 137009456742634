import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../../components/BottomNavigation';
import PageText from '../../../../../../components/PageText';
import { PageTextType } from '../../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../../paths';
import './ConfirmThird1.css';

const ConfirmThird1: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-PageContent Law-LongSentence">
        <div className="Law-Sentence-Margin Law-Sentence-Center Law-Sentence-Bold Law-ConfirmThird-1-Title">
          <FormattedMessage 
            id="fourlaws.confirm.third.one"
            defaultMessage="Thank God often that Christ is in your life and that He will never leave you."
          />
        </div>
        <div className="Law-ConfirmThird-Words">
          <FormattedMessage 
            id="fourlaws.confirm.third.words"
            defaultMessage={'"...for He Himself has said I will never leave you, nor will I ever forsake you" (Hebrews 13:5)'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/second/question/5 (<ConfirmSecondQuestion5 />)
        * nextTo: /4laws/confirm/third/question/1 (<ConfirmThirdQuestion1 />)
        */}
      <BottomNavigation 
        previousTo={`../../${FourLawsPaths.CONFIRM_SECOND}/${FourLawsPaths.CONFIRM_QUESTION}/${FourLawsPaths.INDEX_5}`}
        nextTo={`../${FourLawsPaths.CONFIRM_QUESTION}`}
      />
    </React.Fragment>
  );
};

export default ConfirmThird1;