import React from 'react';
import waterColor from '../../assets/icons/WaterColor.svg';
import { IGeneralProps } from '../../interfaces/page.interfaces';

const WaterColorIcon: React.FC<IGeneralProps> = (props) => {
  return (
    <img className={props.className} src={waterColor} alt='WaterColorIcon' />
  );
};

export default WaterColorIcon;