import React from 'react';
import crossIcon from '../../assets/icons/CrossIcon.svg';
import { IGeneralProps } from '../../interfaces/page.interfaces';

const CrossIcon: React.FC<IGeneralProps> = (props) => {
  return (
    <img className={props.className} src={crossIcon} alt='CrossIcon' />
  );
};

export default CrossIcon;