import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType, PageType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import DiagramLink from '../../../../../components/DiagramLink';
import Law4DiagramLeft from '../../../../../icons/Law4DiagramLeft';
import Law4DiagramRight from '../../../../../icons/Law4DiagramRight';
import { useDispatch } from 'react-redux';
import actionCreators from '../../../../../state/actionCreators';

const LawFourGreet4: React.FC = () => {
  const dispatch = useDispatch();
  const onLeftClick = () => {
    dispatch(actionCreators.setGreetSecondAnswer(PageType.PREP));
  };

  const onRightClick = () => {
    dispatch(actionCreators.setGreetSecondAnswer(undefined));
  };

  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="LawFour-Content">
        <div className="LawFour-Greet-Title Law-Sentence-Bold Law-Sentence-Margin">
          <FormattedMessage 
            id="fourlaws.law4.greet.four"
            defaultMessage="Which circle would you like to have represent your life?"
          />
        </div>
        <div className="LawFour-Greet-flex">
          {/* to: /4laws/four/7 (<LawFourWrapupPrep />) */}
          <DiagramLink to={`../../${FourLawsPaths.INDEX_7}`} onClick={onLeftClick}>
            <Law4DiagramLeft />
          </DiagramLink>
          {/* to: /4laws/four/6 (<LawFourWrapup />) */}
          <DiagramLink to={`../../${FourLawsPaths.INDEX_6}`} onClick={onRightClick}>
            <Law4DiagramRight />
          </DiagramLink>
        </div>
      </PageText>
      {/* to: /4laws/four/greet/3 (<LawFourGreet3 />) */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default LawFourGreet4;