import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import Wrapup1 from './Wrapup1';
import Wrapup2 from './Wrapup2';
import Wrapup3 from './Wrapup3';

const WrapupRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_1} element={<Wrapup1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<Wrapup2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<Wrapup3 />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_1} replace />} />
    </Routes>
  );
};

export default WrapupRoutes;
