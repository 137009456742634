import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import { Language } from '../../../../../interfaces/language.interfaces';
import './LawFour2.css';

const LawFour2: React.FC = () => {
  const intl = useIntl();
  const isKorean = intl.locale === Language.koRR || 
                    intl.locale === Language.koNT;
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawFour-Content Law-LongSentence LawFour-Two"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law4.two.title"
            defaultMessage="We Receive Christ Through Faith"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law4.two.content"
            defaultMessage={'"For by grace you have been saved through faith; and that not of yourselves, ' + 
                            'it is the gift of God; not as a result of works, that no one should boast" (Ephesians 2:8,9).'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/four/1 (<LawFour1 />)
        * nextTo(language=ko-RR || ko-NT): /4laws/four/4 (<LawFour4 />)
        * nextTo(language=en): /4laws/four/3 (<LawFour3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={(isKorean) ? `../${FourLawsPaths.INDEX_4}` : `../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default LawFour2;