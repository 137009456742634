import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Answer from '../../../../../../../components/Answer';
import BottomNavigation from '../../../../../../../components/BottomNavigation';
import PageText from '../../../../../../../components/PageText';
import { PageTextType } from '../../../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../../../paths';
import './ConfirmFirstQuestion3.css';

const ConfirmFirstQuestion3: React.FC = () => {
  const intl = useIntl();
  const answer = intl.formatMessage({
    id: "fourlaws.confirm.first.question.three.answer",
    defaultMessage: "To open the door"
  });
  return (
    <div className="Law-Question">
      <PageText type={PageTextType.CONTENT} className="Law-PageContent Law-LongSentence">
        <div className="Law-Sentence-Margin Confirm1-QWords">
          <FormattedMessage 
            id="fourlaws.confirm.first.question.three.words"
            defaultMessage={'(Christ speaking): "Behold, I stand at the door and knock; ' + 
                            'if <span>anyone hears My voice and opens the door</span>, I will come in to him" (Revelation 3:20).'}
            values={{
              span: (chunks: any) => <span className="Law-Text-Highlight">{chunks}</span>
            }}
          />
        </div>
        <div className="Law-Sentence-Center Law-Question-Below-words">
          <FormattedMessage 
            id="fourlaws.confirm.first.question.three"
            defaultMessage="What is our responsibility?"
          />
        </div>
        <Answer className="Answer-Confirm-first-question3" answer={answer} />
      </PageText>
      {/* 
        * previousTo: /4laws/confirm/first/question/2 (<ConfirmFirstQuestion2 />)
        * nextTo: /4laws/confirm/first/question/4 (<ConfirmFirstQuestion4 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
        nextTo={`../${FourLawsPaths.INDEX_4}`}
      />
    </div>
  );
};

export default ConfirmFirstQuestion3;