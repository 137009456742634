import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PageTextType } from '../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../paths';
import GraceRoutes from './GraceRoutes';
import PageText from '../../../../components/PageText';
import { useMediaQuery } from '@mui/material';
import './Grace.css';

const Grace: React.FC = () => {
  const isTitle: boolean = window.location.href.endsWith(`${FourLawsPaths.GRACE}`) || 
                          window.location.href.endsWith(`${FourLawsPaths.GRACE}/${FourLawsPaths.INDEX_0}`);
  const isWrapup: boolean = window.location.href.endsWith(`${FourLawsPaths.GRACE}/${FourLawsPaths.INDEX_6}`) ||
                            window.location.href.endsWith(`${FourLawsPaths.GRACE}/${FourLawsPaths.INDEX_7}`) ||
                            window.location.href.endsWith(`${FourLawsPaths.GRACE}/${FourLawsPaths.INDEX_8}`);
  const showTitle: boolean = useMediaQuery('(min-height:600px)');
  let pageClassName = "Law-Sentence-Center Law-Title-Animated";
  pageClassName += (!isTitle && !isWrapup) ? ' Law-Title-Animated-content' : '';
  return (
    <div className="Law-PageContent">
      {
        (isWrapup || (!showTitle && !isTitle)) ? null:
        <PageText 
          type={PageTextType.CONTENT} 
          className={pageClassName}
        >
          <div className="Law-Sentence-Bold Law-Sentence-Margin">
            <FormattedMessage 
              id="fourlaws.grace.title.line1"
              defaultMessage="NOW THAT YOU HAVE RECEIVED CHRIST"
            />
          </div>
          <div className="Law-Title-Animated-line2 Grace-Title-line2">
            <FormattedMessage 
              id="fourlaws.grace.title.line2"
              defaultMessage="The moment that you, as an act of faith, received Christ, many things happened, including the following:"
            />
          </div>
        </PageText>
      }
      <GraceRoutes />
    </div>
  );
};

export default Grace;