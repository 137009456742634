import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';

const GracePrep1: React.FC = () => {
  return (
    <React.Fragment>
      <PageText type={PageTextType.CONTENT} className="Law-Sentence-Center Law-Sentence-Bold">
        <FormattedMessage 
          id="fourlaws.grace.prep.one"
          defaultMessage={'1. Christ will come into your life.'}
        />
      </PageText>
      {/* 
        * previousTo: /4laws/grace-prep/2 (<GracePrepMain2 />)
        * nextTo: /4laws/grace-prep/4 (<GracePrep2 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_2}`}
        nextTo={`../${FourLawsPaths.INDEX_4}`}
      />
    </React.Fragment>
  );
};

export default GracePrep1;