import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BottomNavigation from '../../../../../components/BottomNavigation';
import PageText from '../../../../../components/PageText';
import { PageTextType } from '../../../../../interfaces/page.interfaces';
import { FourLawsPaths } from '../../../../paths';
import './LawThree2.css';

const LawThree2: React.FC = () => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <PageText 
        type={PageTextType.CONTENT} 
        className="LawThree-Content Law-LongSentence LawThree-Two"
      >
        <div className="Law-SubTitle">
          <FormattedMessage 
            id="fourlaws.law3.two.title"
            defaultMessage="He Rose From the Dead"
          />
        </div>
        <div>
          <FormattedMessage 
            id="fourlaws.law3.two.content"
            defaultMessage={'"Christ died for our sins... He was buried... He was raised on the third day, ' + 
                            'according to the Scriptures... He appeared to Peter, then to the twelve. ' + 
                            'After that He appeared to more than five hundred..." (1 Corinthians 15:3-6).'}
          />
        </div>
      </PageText>
      {/* 
        * previousTo: /4laws/three/1 (<LawThree1 />)
        * nextTo: /4laws/three/3 (<LawThree3 />)
        */}
      <BottomNavigation 
        previousTo={`../${FourLawsPaths.INDEX_1}`}
        nextTo={`../${FourLawsPaths.INDEX_3}`}
      />
    </React.Fragment>
  );
};

export default LawThree2;