export enum Language {
    en = 'en', //English NIV
    koRR = 'ko-RR', //한국어 개역개정 (Korean Revised Revision)
    koNT = 'ko-NT' //한국어 새번역 (Korean New Translation)
}

export interface LanguageList {
    type: Language;
    label: string;
}

export enum LanguageName {
    KOREAN = 'Korean',
    ENGLISH = 'English'
}