import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FourLawsPaths } from '../../../paths';
import LawOne1 from './LawOne1';
import LawOne2 from './LawOne2';
import LawOneTitle from './LawOneTitle';
import LawOneWrapup from './LawOneWrapup';

const LawOneRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<LawOneTitle />} />
      <Route path={FourLawsPaths.INDEX_1} element={<LawOne1 />} />
      <Route path={FourLawsPaths.INDEX_2} element={<LawOne2 />} />
      <Route path={FourLawsPaths.INDEX_3} element={<LawOneWrapup />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default LawOneRoutes;
