import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FourLawsPaths } from "../../../paths";
import ConfirmFirst from "./ConfirmFirst";
import ConfirmMain from "./ConfirmMain";
import ConfirmSecond from "./ConfirmSecond";
import ConfirmThird from "./ConfirmThird";

const ConfirmRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={FourLawsPaths.INDEX_0} element={<ConfirmMain />} />
      <Route path={FourLawsPaths.CONFIRM_FIRST + '/*'} element={<ConfirmFirst />} />
      <Route path={FourLawsPaths.CONFIRM_SECOND + '/*'} element={<ConfirmSecond />} />
      <Route path={FourLawsPaths.CONFIRM_THIRD + '/*'} element={<ConfirmThird />} />
      <Route path="*" element={<Navigate to={FourLawsPaths.INDEX_0} replace />} />
    </Routes>
  );
};

export default ConfirmRoutes;