import React from 'react';
import koreanIcon from '../../assets/icons/Law2Icon-ko.svg';
import englishIcon from '../../assets/icons/Law2Icon-en.svg';
import { useSelector } from 'react-redux';
import { AppState } from '../../state/reducer';
import { IGeneralProps } from '../../interfaces/page.interfaces';
import { LanguageName } from '../../interfaces/language.interfaces';

const Law2Icon: React.FC<IGeneralProps> = (props) => {
  const language = useSelector((state: AppState) => state.language);
  return (
    (language === LanguageName.KOREAN) ? 
      <img className={props.className} src={koreanIcon} alt='Law2Icon' /> : 
      <img className={props.className} src={englishIcon} alt='Law2Icon' />
  );
};

export default Law2Icon;